<template>
    <div class="container" :style="{ backgroundImage: 'url(' + require('../assets/login_bg.jpg') + ')' }">
            <div class="login-wrapper" @keyup.enter="login"  key="login">
                <div class="login-left">
                    <el-image :src="require('../assets/login_left.png')" />
                </div>

                <div class="login-right">
                    <div class="title">后台管理登录</div>
                    <el-form :model="userInfo" style="width: 300px" ref="form" size="medium">
                        <el-form-item
                            prop="username"
                            :rules="{ required: true, message: '请输入用户名', trigger: 'blur' }"
                        >
                            <el-input
                            v-model="userInfo.username"
                            placeholder="用户名"
                            prefix-icon="el-icon-user" > </el-input>
                        </el-form-item>
                        <el-form-item
                            prop="password"
                            :rules="{ required: true, message: '请输入密码', trigger: 'blur' }"
                        >
                            <el-input
                            v-model="userInfo.password"
                            placeholder="密码"
                            type="password"
                            :show-password="true"
                            prefix-icon="el-icon-lock" ></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-button :loading="loading" @click="login" type="primary" style="width: 100%"
                                >登录
                            </el-button>
                        </el-form-item>
                        <el-form-item label="">
                            <el-checkbox v-model="rememberMe">7天内免登录 </el-checkbox>
                        </el-form-item>
                    </el-form>
                </div>
            </div>
        </transition>
    </div>
</template>
<script>
export default {
    data() {
        return {
            rememberMe: false,
            loading: false,
            userInfo: {
                username: '',
                password: '',
                phone: '',
                code: ''
            },
            registerInfo: {
                username: '',
                password: ''
            },
            activeName: 'first',
            time: 0,
            sending: false
        };
    },
    methods: {
        login() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.loading = true;
                    this.$http
                        .post('/open/login/loginByUserPwd', {
                            username: this.userInfo.username,
                            password: this.userInfo.password,
                            remember: this.rememberMe
                        })
                        .then(res => {
                            let token = res.data.token;
                            localStorage.setItem('token', token);
                            return this.$http.get('/user/my');
                        })
                        .then(res => {
                            this.loading = false;
                            this.$store.commit('updateUserInfo', res);
                            this.$router.replace('/dashboard');
                        })
                        .catch(e => {
                            console.log(e);
                            this.loading = false;
                            this.$message.error(e.error);
                        });
                }
            });
        },
        phonelogin() {
            this.$refs.form2.validate(valid => {
                if (valid) {
                    this.loading = true;
                    this.$http
                        .post('/auth/phoneLogin', {
                            phone: this.userInfo.phone
                        })
                        .then(res => {
                            localStorage.setItem('token', res);
                            return this.$http.get('/user/my');
                        })
                        .then(res => {
                            this.loading = false;
                            this.$store.commit('updateUserInfo', res);
                            this.$router.replace({
                                name: this.$route.params.name || 'dashboard'
                            });
                        })
                        .catch(e => {
                            console.log(e);
                            this.loading = false;
                            this.$message.error(e.error);
                        });
                }
            });
        },
        sendMsg() {
            if (!/^1[3-9]\d{9}$/.test(this.userInfo.phone)) {
                this.$message.warning('手机号格式错误');
                return;
            }
            this.sending = true;
            this.$http
                .get('/sms/sendVerify', {
                    phone: this.userInfo.phone
                })
                .then(res => {
                    this.sending = false;
                    this.checkTime();
                })
                .catch(e => {
                    this.sending = false;
                    console.log(e);
                    this.$message.error(e.error);
                });
        },
        checkTime() {
            this.time = 60;
            let i = setInterval(() => {
                this.time--;
                if (this.time === 0) {
                    clearInterval(i);
                }
            }, 1000);
        }
    }
};
</script>
<style lang="less" scoped>
.container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ebebeb;
    background-image: url('https://microball.oss-cn-hangzhou.aliyuncs.com/izouma/application/rawpixel-com-565462-unsplash%20%281%29.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    overflow: hidden;
}

.login-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    background: white;
    border: 1px #eaeaea solid;
    border-radius:16px;
    position: absolute;
    left:0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    margin:auto;
    width: 780px;
    height: 400px;
    box-shadow: 1px 1px 9px rgba(0,0,0,0.2);
    overflow: hidden;

    .login-left{
        flex:none;
        width:400px;
        height: 100%;
        padding:30px;
        box-sizing: border-box;
        background: linear-gradient(45deg, #d7ebff, #fff);

    }

    .login-right{
        flex:auto;
        padding:30px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .title {
        color: #20a0ff;
        font-size:22px;
        line-height: 80px;
    }
    .register-title {
        color: #20a0ff;
        font-weight: bold;
        font-size: 15px;
    }
}
@media screen and (max-width: 600px) {
    .login-wrapper {
        right: 0;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        height: 265px;
        width: calc(100vw - 30px);
    }
}
.slide-in-enter {
    opacity: 0;
    transform: translateX(50%);
}
.slide-in-enter-active {
    transition: all 0.3s;
}
.slide-in-leave-active {
    opacity: 0;
    transform: translateX(-50%);
    transition: all 0.3s;
}
.slide-out-enter {
    opacity: 0;
    transform: translateX(-50%);
}
.slide-out-enter-active {
    transition: all 0.3s;
}
.slide-out-leave-active {
    opacity: 0;
    transform: translateX(50%);
    transition: all 0.3s;
}

.tab-list {
    width: 350px;
}

.code {
    display: flex;
    .el-button {
        margin-left: 15px;
        min-width: 120px;
    }
}
</style>
